.projects-card-title {

}

.projects-card-title {
    color: #3c4858 !important;
    /* font-size: 1.8em !important; */
    margin-top: 1rem !important;
    /* font-weight: 700 !important; */
    /* line-height: 1.4em !important; */
    margin-bottom: 0.5rem !important;
    text-align: center !important;
}

.projects-card-description {
    color: black !important;
    opacity: 0.6 !important;
    /* font-size: 1.1rem !important;
    font-weight: 300 !important; */
    margin-bottom: 2rem !important;
    text-align: center !important;
}

.projects-basic-title {
    color: black !important;
    opacity: 0.6 !important;
    /* font-size: 1.1rem !important;
    font-weight: 300 !important; */
    margin-bottom: 2rem !important;
    text-align: center !important;
}

.basic-margin-left-container {
    margin-left: 16px !important;
    margin-bottom: 10px !important;
}

.basic-description-input-title {
    margin-left: 16px !important;
}

.projects-card-container {
    margin-bottom: 16px !important;
    /* width: 70% !important;
    margin: auto !important; */
}

.margin-bott0m-in-basic {
    margin-bottom: 35px !important;
}

.forpadding {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.add-button-for-role {
    background: #02ABEE !important;
    color: #fff !important;
    font-family: 'Roboto' !important;
    padding: 5px 10px !important;
}

.btn-alignment {
    margin-left: 50% !important;
    margin-bottom: 16px !important;
}

.basic-margin-description {
    padding-left: 17px !important;
}

.template-role-label-style {
    font-size: 10px !important;
    padding-top: -25px !important;
}

.project-role-dropdown .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 9px 0;
}

.tag-icon-span-on-template {
    left: 0px;
    margin: 6.5rem;
    display: block;
    z-index: 1;
    position: absolute;
    margin-top: 0.8%;
    padding-left: 30px;
}

.template-add-edit-back-button {
    position: relative !important;
    top: 45px !important;
    left: 13px !important;
    cursor: pointer !important;
}
