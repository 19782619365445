.operations-information-title {
    font-size: 32px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important;
    color: #77797c !important;
}

.operations-titles {
    font-size: 24px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important;
    color: #77797c !important;
}

.day-name {
    text-align: center !important;
    margin-top: 16% !important;
}

.add-icon {
    text-align: start !important;
    margin-top: 40% !important;
    cursor: pointer !important;
}

.holiday-list {
    border-top: 1px solid #eee !important;
    padding: 20px 10px !important;
    margin-top: 10px !important;
}

.holiday {
    font-size: 14px !important;
    text-align: center !important;
    padding: 5px !important;
    border-radius: 5px !important;
    background: #CCF1FF;
}

.add-holiday-name {
    margin-top: 8% !important;
}

.holiday-true-false {
    margin-top: 17% !important;
    margin-left: 3% !important;
}

.add-holiday-day-data {
    margin-top: 8% !important;
}

.add-time-icon {
    margin-top: 6% !important;
    vertical-align: -webkit-baseline-middle;
    cursor: pointer;
}

.remove-time-icon {
    margin-top: 6% !important;
    vertical-align: -webkit-baseline-middle;
    cursor: pointer;
}

.add-button-holiday {
    margin-top: 16% !important;
}

.start-time-picker-grid {
    padding: 5px 5px !important;
}

.end-time-picker-grid {
    padding: 5px 5px !important;
    margin-left: -10px !important;
}
