.team-list-count-assignee {
    width: 32px !important;
    height: 32px !important;
    padding: 2px;
    background: #CCF1FF !important;
    color: black !important;
    margin-left: -6px !important;
    border-radius: 50% !important;
    margin-bottom: 4px !important;
    font-size: 14px !important;
}

.team-list-count-assignee .MuiAvatar-colorDefault {
    background: none !important;
    color: normal !important;
}

.new-team-list .MuiTableRow-root:hover {
    background: #0faeec17 !important
}
