.float-right {
    float: right !important;
}

.padding-top-2 {
    padding-top: 2% !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-6 {
    margin-top: 6px !important;
}

.float-left {
    float: left !important;
}

.history-btn-style {
    cursor: pointer !important;
}

.avatar-style {
    height: 30px !important;
    width: 30px !important;
}

.for-display-flex {
    display: flex !important;
}

.for-padding-right-avatar {
    padding-right: 5px !important;
    position: relative !important;
    bottom: 7px !important;
}

.history-blocks {
    padding: 10px 10px !important;
    margin: 10px 10px !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
}

.history-current-btn-position {
    position: absolute !important;
    top: 17px !important;
    left: 796px !important;
}
