.stepper-container .MuiPaper-root {
    background-color: #f2f6fa !important;
    width: 15% !important;
    margin-left: 42% !important;
}

.back-next-btn {
    display: flex;
    justify-content: flex-end;
}

.reset-btn {
    display: flex;
    justify-content: flex-end;
}
