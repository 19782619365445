.item-name-column {
    margin-left: 1.4% !important;
    /* color: #428bca !important; */
    cursor: pointer;
    font-size: 14px !important;
}

.no-records-found {
    margin-left: 1.4% !important;
    color: #77797c !important;
    cursor: pointer;
    font-size: 14px !important;
}

.trash-icon {
    /* margin-right: 5.7% !important; */
    color: #428bca !important;
    font-size: 14px !important;
    cursor: pointer;
}

.checklist-item-list {
    padding: 10px 0px;
    line-height: 24px;
    border-bottom: 1px dashed #eee;
}

.item-description-column {
    margin-left: 1.4% !important;
    font-size: 14px !important;
}

.checklist-item-description-list {
    padding: 10px 0px;
    line-height: 24px;
    border-bottom: 1px dashed #eee;
    /* color: #77797c !important; */
}

.input-item-description {
    margin-top: 1% !important;
}

.trash-icon-description-list {
    margin-right: 5.7% !important;
    /* color: #77797c !important; */
    font-size: 14px !important;
    cursor: pointer;
}

.dashed-line {
    max-width: 100% !important;
    border-top: 1px dashed #77797c;
}

.add-items-btn-grid {
    margin-top: 5px !important;
}

.additem-btn {
    background: #02ABEE !important;
    color: #fff !important;
    font-family: 'Roboto' !important;
    margin-left: -6px !important;
}
