.task-info-title {
    /* font-size: 32px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important; */
    /* color: #77797c !important; */
    /* line-height: 45px !important; */
}

.block-titles {
    /* font-size: 24px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important; */
    /* color: #77797c !important; */
    /* line-height: 34px !important; */
}

.chip-render-in-table .MuiChip-root {
    border-radius: 3px !important;
    margin-top: 4px !important;
    padding: 2px 5px !important;
    /* background-color: #02ABEE !important; */
    color: white !important;
    margin-left: 4px !important;
}

.task-table .MuiTableCell-sizeSmall {
    padding: 10px 24px 10px 16px !important;
}

.add-task-button.MuiButton-root {
    /* margin-top: 30% !important; */
    position: relative !important;
    top: 80% !important;
    /* border: 1px #02ABEE solid !important; */
    /* background: #fff !important; */
    /* color: #02ABEE !important; */
    /* font-family: 'Roboto' !important; */
    /* padding: 5px 5px !important; */
}

.nextbuttontasks.MuiButton-root {
    margin: 5px !important;
    background: #02ABEE !important;
    color: #fff !important;
    font-family: 'Roboto' !important;
    padding: 5px 10px !important;
    margin-left: 60% !important;
}

.title-tasklist-table {
    /* color: #02ABEE !important ; */
    cursor: pointer;
}

.select-typography {
    margin-top: 7% !important;
    margin-right: 5px !important;
}

.link-btn-typography {
    margin-top: 4% !important;
    margin-right: 5px !important;
}

.dashed-line {
    max-width: 100% !important;
    border-top: 1px dashed #77797c;
}

.dailog-box-tasklist-add .MuiDialog-paperWidthSm {
    max-width: 60% !important;
}

.dailog-box-tasklist-add .MuiDialog-scrollPaper {
    align-items: flex-start !important;
}

.chip-container {
    margin-top: 15px !important;
}

.marginTop {
    margin-top: 15px !important;
}

.description-container {
    margin-top: -20px !important;
}

.MuiNativeSelect-icon {
    display: none !important;
}

.MuiSelect-icon {
    display: none !important;
}

.list-of-item .MuiInputBase-root {
    font-weight: normal !important;
}

.checklist-title-container {
    margin-left: 25px !important;
}

/* .nextbuttontasks.MuiButton-contained {
    background: #02ABEE !important;
} */
.header-actions-btns {
    /* color: #02ABEE !important ; */
    /* border-bottom: 1px dashed #02ABEE !important; */
    padding: 0 10px 10px 10px !important;
}

.header-icons-styling {
    font-size: 20px !important;
    cursor: pointer;
}

.tags-contianer .MuiFilledInput-underline:before {
    border-bottom: none !important;
}

.edit-icon-task-config {
    cursor: pointer !important;
    /* color:  #02ABEE !important ;; */
}

.task-table .MuiTableCell-body {
    color: #77797c !important;
}

.tag-icon-span-on-edit-tasklist {
    left: 0px;
    margin: 6.5rem;
    display: block;
    z-index: 1;
    position: absolute;
    margin-top: 1%;
    padding-left: 30px;
}

.margin-top-15 {
    margin-top: 15px !important;
}
