.draft-status-container {
    height: calc(100vh - 191px) !important;
    font-size: 16px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 300 !important;
    line-height: 20px !important;
    letter-spacing: 0.00938em !important;
    /* color: #77797c !important; */
    text-align: center !important;
}

.draft-message-span {
    margin-top: 25% !important;
    text-align: center !important;
}

.participants-table-container .MuiTableCell-body {
    color: #77797c !important;
}

.for-box-shadow .MuiTableCell-body {
    color: #77797c !important;
}

.for-card-shadow {
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
}

.padding-for-description-container {
    padding: 15px 0 15px 10px !important;
}