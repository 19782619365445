.login-container {
    height: 100% !important;
    background: #e5f4ff url('../../../assets/worldSkyline.png') no-repeat !important;
    background-size: contain !important;
    background-position: bottom center !important;
    margin: 0 !important;
    text-align: center !important;
}

.login-form-container {
    max-width: 900px !important;
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.login-form-holder {
    padding: 50px 0 !important;
}

.login-brand {
    padding: 20px !important;
    background: url('../../../assets/teamRocket.jpg') !important;
    background-size: cover !important;
    background-position: center center !important;
    color: #fff !important;
    min-height: 540px !important;
    position: relative !important;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3) !important;
    transition: all 0.6s cubic-bezier(1, -0.375, 0.285, 0.995) !important;
    z-index: 1 !important;
}

.login-brand::before {
    content: '' !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    background: rgba(255, 255, 255, 0.15) !important;
    z-index: -1 !important;
}

.login-logo-container {
    font-size: 32px !important;
    font-weight: 700 !important;
    text-decoration: none !important;
    line-height: 40px !important;
    display: block !important;
}

.login-logo {
    vertical-align: middle !important;
    height: 32px !important;
    width: 32px !important;
}

.login-logo-text {
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #fff !important;
    transform: translateX(-5px) !important;
    display: inline-block !important;
    margin-left: 0.6rem !important;
}

.main-form {
    position: relative !important;
}

.switched {
    transform: translateX(-100%) !important;
    width: 100% !important;
    left: 0 !important;
}

.main-form-piece {
    background: #fff;
    min-height: 480px;
    margin-top: 30px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    color: #bbbbbb;
    padding: 30px 0 60px;
    transition: all 0.9s cubic-bezier(1, -0.375, 0.285, 0.995);
    position: absolute;
    top: 0;
    left: -30%;
    width: 130%;
    overflow: hidden;
    text-align: left;
}

.login-main-form {
    padding: 0 40px;
    margin: 0;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.login-form-heading {
    font-size: 2rem !important;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
}

.login-form-group {
    margin-bottom: 5px;
    position: relative;
    margin-top: 1.5rem;
}

.login-form-activate-text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.login-form-buttons-container {
    margin-top: 30px;
}

.login-submit-button {
    margin-right: 20px !important;
}

.login-form-switch {
    font-size: 13px !important;
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #bbbbbb !important;
    text-decoration: underline !important;
    transition: all 0.3s !important;
}

.login-form-switch:hover {
    color: #f95959 !important;
    cursor: pointer !important;
}

.login-footer {
    text-align: center;
}

.login-footer-text {
    color: #777;
}

@media (max-width: 768px) {
    .login-form-holder {
        padding: 0 !important;
    }

    .login-brand {
        min-height: 200px !important;
    }

    form.login-main-form {
        margin-left: 10vw !important;
        left: 42% !important;
        width: 100% !important;
        padding: 50px !important;
    }

    .login-form-heading {
        font-size: 3rem !important;
    }

    .main-form-piece {
        margin: 0 !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        transition: all 0.5s ease-in-out !important;
    }

    .switched {
        transform: translateY(-100%) !important;
        width: 100% !important;
        left: 0 !important;
    }

    .login-footer {
        text-align: center !important;
        position: absolute !important;
        bottom: 14px !important;
        width: 100% !important;
    }
}

.font-weight-400 {
    font-weight: 400 !important;
}
