.blank-screen-new-team {
    height: calc(100vh - 191px) !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
}

.new-team-detail-panel {
    height: calc(100vh - 115px) !important;
    /* min-height: 100% !important; */
    margin-left: 10px !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
    position: sticky !important;
    top: 90px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.new-team-list {
    /* height: calc(100vh - 191px) !important; */
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    /* height: 100% !important; */
    border-radius: 6px !important;
    min-height: 520px !important;
}

.new-team-list.MuiCard-root {
    overflow: auto !important;
}

.table-title-style {
    background-color: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0 16px 5px 16px;
    height: 46px;
    /* border-bottom: 1px dashed #02ABEE; */
}

.button-grid-margin-top {
    margin-top: 25% !important;
}

.welcome-title-style {
    font-size: 16px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 300 !important;
    line-height: 20px !important;
    letter-spacing: 0.00938em !important;
    /* color: #77797c !important; */
}

.info-blank-page {
    margin-top: 20px !important;
}

.blue-color-btns {
    border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important;
    padding: 5px 5px !important;
}

.margin-top-team-btn {
    margin-top: 8px !important;
}

.loader-for-save-and-edit {
    position: relative !important;
    top: 48% !important;
    margin-left: 44% !important;
}
.blank-page-button-milestone.MuiButton-root.MuiButton-sizeSmall{
    height: 27px !important;
}