.header-card {
    padding: 10px !important;
    box-shadow: rgb(0 0 0 / 29%) 0px 2px 5px, rgb(0 0 0 / 10%) 0px 1px 2px !important;
}

.margin-left-for-template-btn {
    margin-left: 5px !important;
}

.text-containt {
    color: #77797c !important;
    margin-top: 10px !important;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: normal !important;
    line-height: 20px;
    letter-spacing: 0.00938em;
}

.filter-container {
    display: inline-flex !important;
}

.filter-box {
    border: 1px solid #77797c !important;
    border-radius: 4px !important;
    margin-left: 5px !important;
}

.inputbase-style {
    margin-top: 6px !important;
    margin-left: 6px !important;
}

.new-template-btn {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 5px 5px !important;
    margin-top: 4px !important;
}

.body-card {
    padding: 20px !important;
    margin-top: 25px !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    box-shadow: rgb(0 0 0 / 29%) 0px 2px 5px, rgb(0 0 0 / 10%) 0px 1px 2px !important
}

.subcard-header {
    padding: 5px !important;
}

.subcard-image {
    width: 30px !important;
    height: 30px !important;
}

.subcard-title {
    /* color: #f5721e !important; */
    /* font-size: 1rem !important; */
    min-width: 0px !important;
    /* font-weight: 550 !important; */
    /* margin-top: 5px !important; */
    margin-left: 2px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subcard-actions {
    /* color: #02ABEE !important; */
    margin-left: 16px !important;
    cursor: pointer !important;
}

.subcard-edit-icon {
    margin-left: -29px !important;
}

.subcard-delete-icon {
    margin-left: 14px !important;
    color: red !important;
}

.subcard-description-container {
    padding: 0 10px 13px 10px;
    /* color: #77797c !important; */
}

.subcard-description {
    font-weight: normal !important;
}

.body-subcard {
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
    /* border-bottom: 1px solid #e0e0e0 !important; */
    box-shadow: rgb(0 0 0 / 29%) 0px 2px 5px, rgb(0 0 0 / 10%) 0px 1px 2px !important;
}

.subcard-title-container {
    cursor: pointer !important;
    margin-left: 10px !important;
}

.tagcontainer-box {
    border: 1px solid #c5e2ff;
    position: relative;
    /* background-color: #f2f6fa; */
}

.templates-landing-page-chips-container {
    margin-bottom: 20px !important;
}
