.card-table-view-container {
    height: calc(100vh - 191px) !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
}

.card-table-on-btn-click {
    min-height: 520px !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
}

.card-table-on-btn-click.MuiCard-root {
    /* overflow: auto !important; */
}

.welcome-title {
    font-size: 16px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 300 !important;
    line-height: 20px !important;
    letter-spacing: 0.00938em !important;
    /* color: #77797c !important; */
}

.add-update-form {
    height: calc(100vh - 115px) !important;
    margin-left: 10px !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
    position: sticky !important;
    top: 90px !important;
}

.add-update-form-onclick {
    /* height: calc(100vh - 115px)  !important; */
    height: auto !important;
    margin-left: 10px !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
    position: sticky !important;
    top: 90px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.info-blank-page {
    display: flex;
    align-items: center;
    justify-content: center;
}

.for-margin-top {
    margin-top: 25% !important;
}

.blank-page-button-milestone {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 5px 5px !important;
}

.blank-page-button-tasklist {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 5px 5px !important;
    margin-left: 10px !important;
}

.template-header-buttons-style {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 0 5px !important;
}

.template-header-buttons-style-for-tasklist {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 0 5px !important;
    margin-left: 10px !important;
}

.btn-margin-left {
    margin-left: 5px !important;
    font-size: 14px !important;
}

.template-header-buttons-style-for-tasklist.MuiButton-root {
    text-transform: none !important;
}

.template-header-buttons-style.MuiButton-root {
    text-transform: none !important;
}

.template-header-buttons-style-for-tasklist.MuiButton-root {
    font-weight: 300 !important;
}

.template-header-buttons-style.MuiButton-root {
    font-weight: 300 !important;
}

.card-table-on-btn-click .MuiTableCell-body {
    color: #77797c !important;
}
.for-padding-bottom-10 {
    padding-bottom: 10px !important;
}
