:root {
    --common-black: #000000;
    --common-white: #ffffff;

    --primary-light: #6bb5cb;
    --primary-main: #46a3be;
    --primary-dark: #317285;
    --primary-text: #ffffff;

    --secondary-light: #f37779;
    --secondary-main: #f05658;
    --secondary-dark: #a83c3d;
    --secondary-text: #ffffff;
}


body{
    font-family: "Roboto", sans-serif !important;
}
.float-right {
    float: right !important;
    margin-left: 10px !important;
}

.display-ellipsis-line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.text-overflow-ellipsis {
    white-space: nowrap;
    width: 150px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.MuiSnackbar-anchorOriginTopRight {
    top: 70px !important;
}

.MuiSnackbar-root {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.MuiPickersToolbarText-toolbarTxt.MuiPickerDTToolbar-separator.MuiTypography-h3 {
    position: relative !important;
    top: -7px !important;
}
