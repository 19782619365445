.basic-information-title {
    font-size: 32px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important;
    color: #77797c !important;
}

.information-titles {
    font-size: 24px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important;
    color: #77797c !important;
}

.phone-number .react-tel-input .form-control {
    width: 252px !important;
}

.phone-number .form-control {
    height: 46px !important;
}
