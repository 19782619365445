.role-info-title {
    /* font-size: 32px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important; */
    /* color: #77797c !important; */
    /* line-height: 45px !important; */
}

.block-titles {
    /* font-size: 24px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important; */
    /* color: #77797c !important; */
    /* line-height: 34px !important; */
}

.margin-top-20 {
    margin-top: 20px !important;
}

.role-details-container {
    margin-top: 0px !important;
}

.add-role-icon {
    vertical-align: -webkit-baseline-middle;
    margin-top: 12px !important;
    cursor: pointer;
}

.remove-role-icon {
    vertical-align: -webkit-baseline-middle;
    margin-top: 12px !important;
    cursor: pointer;
}

.save-button,
.reset-button {
    float: right;
    margin-left: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
