.card-table-on-btn-click .MuiIcon-root {
    display: none !important;
}

.import-tasklist-dropdown {
    padding: 24px 0 !important;
}

.for-display-flex {
    display: flex !important;
}

.padding-top-8 {
    padding-top: 8px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.visibility-hidden-class {
    visibility: hidden !important;
}

.position-relative-class {
    position: relative !important;
}

.top-2 {
    top: -2px !important;
}

.top-4 {
    top: 4px !important;
}

.font-size-20 {
    font-size: 20px !important;
}
