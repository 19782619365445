/* .MuiTableCell-sizeSmall {
    padding: 6px -1px 6px 16px !important;
} */

.width-100-per {
    width: 100% !important;
}

.back-arrow-icon {
    margin: auto;
    font-size: 20px !important;
    margin-right: 16px !important;
    cursor: pointer;
}

.right-icon {
    margin-left: -8px;
    font-size: 19px;
    margin-top: -12px;
    color: #77797c !important;
}

.down-icon {
    margin-left: -14px;
    font-size: 19px;
    margin-top: -12px;
    color: #77797c !important;
}

.flag-icon {
    margin-left: -14px;
    font-size: 14px;
    margin-top: -12px;
    color: #77797c !important;
}

.MuiTableCell-sizeSmall {
    /* padding: 16px !important; */
}

.task-user-image {
    width: 32px;
    height: 32px;
    padding: 2px;
    background: #CCF1FF;
    margin-left: -6px;
    border-radius: 50%;
    margin-bottom: 4px;
}

.user-if-one {
    display: inline-flex !important;
}

.user-name-in-assignee {
    margin-top: 6px !important;
}

.user-count-assignee {
    color: #000;
    width: 32px !important;
    height: 32px !important;
    margin: 0px 0 4px -10px !important;
    display: inline-block !important;
    padding: 2px !important;
    background: #CCF1FF !important;
    text-align: center !important;
    line-height: 32px !important;
    border-style: none !important;
    border-radius: 50% !important;
    vertical-align: middle !important;
}

.table-title {
    background-color: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0 16px 5px 8px;
    /* border-bottom: 1px dashed  #02ABEE; */
}

/* .MuiTableCell-root {
    background-color: #fff !important;
} */

.project-blank-page-button-milestone {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 0 5px !important;
}

.project-blank-page-button-tasklist {
    /* border: 1px #02ABEE solid !important;
    background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important; */
    padding: 0 5px !important;
    margin-left: 10px !important;
}

.style-for-table-header-icons {
    font-size: 16px !important;
}

.project-blank-page-button-milestone.MuiButton-root {
    text-transform: none !important;
    font-weight: 300 !important;
}

.project-blank-page-button-tasklist.MuiButton-root {
    text-transform: none !important;
    font-weight: 300 !important;
}

.table-header-icon-title {
    font-size: 14px !important;
}

.back-btn-styling-forvertical-align {
    margin-top: 9px !important;
}

.popper-style {
    margin-top: 13px !important;
    z-index: 1 !important;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.import-menuItem-styling {
    /* background: #fff !important;
    color: #02ABEE !important;
    font-family: 'Roboto' !important;
    font-weight: 300 !important; */
    text-transform: none !important;
}

.clickbale-icon {
    padding-left: 6px !important;
    padding-right: 5px !important;
    margin-top: 2px !important;
}

.for-padding-right {
    padding-right: 4px !important;
}

.for-border-right {
    /* border-right: 1px solid #02ABEE ; */
}

.for-box-shadow {
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%);
    border-radius: 6px !important;
    min-height: 520px !important;
    background-color: #ffff !important;
}

.for-box-shadow .MuiTableRow-root {
    height: 50px !important;
}

.milestone-flag-icon {
    visibility: hidden !important;
}

.table-view-tasklist-container .MuiTableRow-root:hover .milestone-flag-icon {
    visibility: visible !important;
}

.drag-icon {
    display: none !important;
}

.MuiTableRow-root:hover .drag-icon {
    display: block !important;
}

.for-display-flex {
    display: flex !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.visibility-hidden {
    visibility: hidden !important;
}

.save-and-edit-loader {
    position: relative !important;
    top: -2px !important;
}

.margin-left-3 {
    margin-left: 3px !important;
}

.margin-left-8 {
    margin-left: -8px !important;
}

.import-tasklist-div {
    padding: 24px 0px !important;
}

.tasklist-back-icon {
    position: relative !important;
    top: 4px !important;
}

.mytask-style {
    position: relative !important;
    top: 2px !important;
    left: 15px !important;
    font-size: 14px !important;
    font-weight: 300px !important;
    cursor: pointer !important;
}

.all-style {
    position: relative !important;
    top: 2px !important;
    left: 30px !important;
    font-size: 14px !important;
    font-weight: 300px !important;
    cursor: pointer !important;
}

.active-tasks-tab {
    color: #f5721e !important;
}

.padding-top-8 {
    padding-top: 8px !important;
}

.overflow-styling {
    width: 120px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    word-wrap: break-word !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
}
.for-font-size {
    font-size: 20px !important;
}
