.team-detail-panel-user-icon {
    font-size: 32px !important;
    color: #77797c !important;
}

.form-panel-user-icon-container {
    margin-top: -3px;
}

.team-detail-panel-add-member-box {
    display: inline-flex;
}

.team-detail-panel-add-user-icon {
    /* color: #77797c; */
}

.team-detail-panel-new-member-text {
    /* color: #77797c !important; */
    text-align: center !important;
    font-weight: 500 !important;
    margin-left: 5px !important;
    border-radius: 5px !important;
    /* font-weight: normal !important; */
}

.team-detail-panel-memeber-container {
    margin-top: 8px !important;
}

.team-detail-member-box {
    border: 1px solid #77797c96 !important;
    border-radius: 4px !important;
    margin-left: 5px !important;
    margin-top: -4px;
}

.team-detail-panel-inputbase {
    padding-left: 5px !important;
    padding-right: 5px !important;
    border: 1px solid #77797c96 !important;
    border-radius: 4px !important;
    margin-left: 5px !important;
    margin-top: -4px;
}

.team-detail-panel-add-member-btn {
    margin-top: -4px !important;
}

.team-detail-panel-delete-memeber-icon {
    color: rgb(196, 39, 39) !important;
    cursor: pointer;
    margin-top: 1px;
}

.team-detail-panel-memeber-name-text {
    /* color: #77797c !important; */
    /* text-align: center !important; */
    /* margin-left: 5px !important; */
    border-radius: 5px !important;
    font-weight: normal !important;
}

.team-detail-panel-padding {
    height: 50px !important;
    padding: 4px 20px 16px 10px !important;
    /* border-bottom: 1px dashed #02ABEE !important; */
}

.team-detail-panel-team-lead-user-image {
    margin-top: 6px !important;
    width: 32px !important;
    height: 32px !important;
    /* font-size: 32px !important; */
    /* color: #77797c !important; */
}

.btn-cursor {
    cursor: pointer !important;
}

.padding-to-grid {
    padding: 10px !important;
}

.client-vendor-background-color {
    background-color: #f2f6fa !important;
}

.edit-icon-small {
    /* color: #02ABEE !important; */
    cursor: pointer;
}

.image-icon-grid {
    width: 79px;
    margin-left: -3px;
}

.image-icon-grid-by-default {
    width: 40px !important;
    height: 40px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;

}

.client-vendor-compnay-name {
    font-size: 25px !important;
    color: black !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    /* font-weight: normal !important; */
    line-height: 1.57 !important;
    letter-spacing: 0.00714em !important;
}

.client-vendor-contact-icons {
    /* color: #77797c !important; */
    font-size: 25px !important;
}

.client-vendor-contact-text-grid {
    margin-left: 5px !important;
}

.client-vendor-contact-person {
    /* color: #77797c !important; */
    margin-left: 6px !important;
    border-radius: 5px !important;
    /* font-size:10px !important;
    font-weight: normal !important; */
}

.client-vendor-contact-person-name {
    /* color: #77797c !important; */
    margin-top: -7px !important;
    margin-left: 7px;
}

.client-vendor-input-grid {
    margin-left: 10px !important;
    margin-top: -7px !important;
}

.client-vendor-input-grid-for-contact-phone {
    margin-left: 10px !important;
    margin-top: -3px !important;
}

.client-vendor-contact-person-phone-email {
    /* color: #02ABEE !important; */
    margin-top: -7px !important;
    margin-left: 7px;
}

.client-vendor-contact-person-phone-email .MuiTypography-body1 {
    font-weight: normal !important;
}

.client-vendor-click-to-add-text {
    margin-left: 9px !important;
}

.team-detail-panel-inputbase .MuiIconButton-label {
    display: none !important;
}

.autocomplete-text-filed {
    margin-top: -10px !important;
}

.autocomplete-text-filed .MuiInputBase-root {
    font-size: 16px !important;
    font-weight: 300 !important;
    color: #77797c !important;
}

.team-detail-panel-inputbase .MuiInputBase-root {
    font-size: 16px !important;
    font-weight: 300 !important;
    color: #77797c !important;
}

.team-detail-panel-save-btn {
    /* color: #02ABEE !important; */
    cursor: pointer !important;
    font-size: 20px !important;
}
.save-btn-position-style {
    position: relative !important;
    right: -8px !important;
    top: 2px !important;
}
.team-detail-panel-inputbase .MuiInput-underline:before {
    border-bottom: none !important;
}

.disable-add-btn {
    cursor: not-allowed !important;
}

.margin-top-six {
    margin-top: 6px !important;
}

.for-padding-ten {
    padding: 0 10px 0 10px !important;
}

.for-padding-description {
    padding: 0 10px 10px 10px !important;
}

.memebers-title-on-read-only {
    padding: 5px 0 0 10px !important;
}

.error-message-phone {
    color: red !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}
.padding-bottom-10 {
    padding-bottom: 10px !important;
}
.clients-detail-panel-padding{
    height: 45px !important;
    padding: 4px 20px 16px 10px !important;
}
