.dailog-box-for-task-add .MuiDialog-scrollPaper {
    align-items: flex-start !important;
}

.dailog-box-for-task-add .MuiDialogContent-root {
    overflow-y: unset !important;
}

.reset-dialog-button.MuiButton-root {
    /* background: #ffcdd2 !important;
    color: red !important;
    font-family: 'Roboto' !important; */
    padding: 5px 10px !important;
}

.save-dialog-button.MuiButton-root {
    /* background: #02ABEE !important;
    color: #fff !important;
    font-family: 'Roboto' !important; */
    padding: 5px 10px !important;
}

.dailog-box-for-task-add .MuiDialog-paperWidthSm {
    max-width: 60% !important;
}

.MuiButton-contained {
    box-shadow: none !important;
}

.tags-contianer {
    border: 1px solid #c5e2ff;
    /* border-bottom: 1px solid #c5e2ff !important;
    border-right: 1px solid #c5e2ff !important;
    border-top: 1px solid #c5e2ff !important; */
    background-color: #f2f6fa;
    border-radius: 5px !important;
}

.tags-contianer .MuiChip-outlined {
    color: #fff !important;
    border: 1px solid #fff !important;
    margin: 7px 7px !important;
    margin-right: 0px !important;
    border-radius: 3px !important;
    background-color: #02ABEE !important;
}

.tags-contianer .MuiFilledInput-root {
    background-color: #f2f6fa;
}

.tags-contianer .MuiFilledInput-root:hover {
    background-color: #f2f6fa;
}

.tags-contianer .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-left: 50px !important;
    padding-top: 2px !important;
    padding-right: 39px !important;
}

.tag-icon-span {
    left: 0px;
    margin: 0.5rem;
    display: block;
    z-index: 1;
    position: absolute;
    margin-top: 1%;
    padding-left: 30px;
}
