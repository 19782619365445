.change-password-card-container {
    padding: 24px !important;
}

.change-password-form-button {
    float: right !important;
}

.change-password-cancel-button {
    float: right !important;
    margin-right: 10px !important;
}
