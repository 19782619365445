
.display-loader {
    /* opacity: 1; */
    display: block;
}

.hide-loader {
    /* opacity: 0; */
    display: none;
}

/* First Loader */
.loader-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.circular-progress-app-loader {
    color: #fff !important;
    margin-top: 20px !important;
}

.circular-progress-app-loader-for-save-edit {
    color: black !important;
    margin-top: 11px !important;
}

.scroll-loader-container {
    position: static !important;
    height: 100px !important;
    background-color: #13191e !important;
    text-align: center;
}

/* First Loader */
.scroll-loader-container {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    margin: auto;
    width: 100px;
    height: 100px;
}

.loader-container {
    align-items: center;
    /* background: #d65b9e; */
    /* background: linear-gradient(45deg, #d65b9e 1%, #f699cb 22%, #ffacd9 51%, #f699cb 83%, #d65b9e 100%); */
    display: flex;
    justify-content: center;
}

.loader {
    position: relative;
}

.loader .dot {
    -webkit-animation-name: movement;
    animation-name: movement;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    height: 5px;
    position: absolute;
    top: -5px;
    -webkit-transform: translate3d(0, -15px, 0) scale(1);
    transform: translate3d(0, -15px, 0) scale(1);
    width: 5px;
}

.loader .dot:nth-of-type(1) {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
    left: 90px;
}

.loader .dot:nth-of-type(1)::before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

.loader .dot:nth-of-type(2) {
    -webkit-animation-delay: -0.95s;
    animation-delay: -0.95s;
    left: 90px;
}

.loader .dot:nth-of-type(2)::before {
    -webkit-animation-delay: -0.95s;
    animation-delay: -0.95s;
}

.loader .dot:nth-of-type(3) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
    left: 75px;
}

.loader .dot:nth-of-type(3)::before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.loader .dot:nth-of-type(4) {
    -webkit-animation-delay: -1.15s;
    animation-delay: -1.15s;
    left: 75px;
}

.loader .dot:nth-of-type(4)::before {
    -webkit-animation-delay: -1.15s;
    animation-delay: -1.15s;
}

.loader .dot:nth-of-type(5) {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    left: 60px;
}

.loader .dot:nth-of-type(5)::before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.loader .dot:nth-of-type(6) {
    -webkit-animation-delay: -1.35s;
    animation-delay: -1.35s;
    left: 60px;
}

.loader .dot:nth-of-type(6)::before {
    -webkit-animation-delay: -1.35s;
    animation-delay: -1.35s;
}

.loader .dot:nth-of-type(7) {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
    left: 45px;
}

.loader .dot:nth-of-type(7)::before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.loader .dot:nth-of-type(8) {
    -webkit-animation-delay: -1.55s;
    animation-delay: -1.55s;
    left: 45px;
}

.loader .dot:nth-of-type(8)::before {
    -webkit-animation-delay: -1.55s;
    animation-delay: -1.55s;
}

.loader .dot:nth-of-type(9) {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
    left: 30px;
}

.loader .dot:nth-of-type(9)::before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.loader .dot:nth-of-type(10) {
    -webkit-animation-delay: -1.75s;
    animation-delay: -1.75s;
    left: 30px;
}

.loader .dot:nth-of-type(10)::before {
    -webkit-animation-delay: -1.75s;
    animation-delay: -1.75s;
}

.loader .dot:nth-of-type(11) {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
    left: 15px;
}

.loader .dot:nth-of-type(11)::before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.loader .dot:nth-of-type(12) {
    -webkit-animation-delay: -1.95s;
    animation-delay: -1.95s;
    left: 15px;
}

.loader .dot:nth-of-type(12)::before {
    -webkit-animation-delay: -1.95s;
    animation-delay: -1.95s;
}

.loader .dot:nth-of-type(13) {
    -webkit-animation-delay: -1.3s;
    animation-delay: -1.3s;
    left: 0px;
}

.loader .dot:nth-of-type(13)::before {
    -webkit-animation-delay: -1.3s;
    animation-delay: -1.3s;
}

.loader .dot:nth-of-type(14) {
    -webkit-animation-delay: -2.15s;
    animation-delay: -2.15s;
    left: 0px;
}

.loader .dot:nth-of-type(14)::before {
    -webkit-animation-delay: -2.15s;
    animation-delay: -2.15s;
}

.loader .dot:nth-of-type(15) {
    -webkit-animation-delay: -1.5s;
    animation-delay: -1.5s;
    left: -15px;
}

.loader .dot:nth-of-type(15)::before {
    -webkit-animation-delay: -1.5s;
    animation-delay: -1.5s;
}

.loader .dot:nth-of-type(16) {
    -webkit-animation-delay: -2.35s;
    animation-delay: -2.35s;
    left: -15px;
}

.loader .dot:nth-of-type(16)::before {
    -webkit-animation-delay: -2.35s;
    animation-delay: -2.35s;
}

.loader .dot:nth-of-type(17) {
    -webkit-animation-delay: -1.7s;
    animation-delay: -1.7s;
    left: -30px;
}

.loader .dot:nth-of-type(17)::before {
    -webkit-animation-delay: -1.7s;
    animation-delay: -1.7s;
}

.loader .dot:nth-of-type(18) {
    -webkit-animation-delay: -2.55s;
    animation-delay: -2.55s;
    left: -30px;
}

.loader .dot:nth-of-type(18)::before {
    -webkit-animation-delay: -2.55s;
    animation-delay: -2.55s;
}

.loader .dot:nth-of-type(19) {
    -webkit-animation-delay: -1.9s;
    animation-delay: -1.9s;
    left: -45px;
}

.loader .dot:nth-of-type(19)::before {
    -webkit-animation-delay: -1.9s;
    animation-delay: -1.9s;
}

.loader .dot:nth-of-type(20) {
    -webkit-animation-delay: -2.75s;
    animation-delay: -2.75s;
    left: -45px;
}

.loader .dot:nth-of-type(20)::before {
    -webkit-animation-delay: -2.75s;
    animation-delay: -2.75s;
}

.loader .dot:nth-of-type(21) {
    -webkit-animation-delay: -2.1s;
    animation-delay: -2.1s;
    left: -60px;
}

.loader .dot:nth-of-type(21)::before {
    -webkit-animation-delay: -2.1s;
    animation-delay: -2.1s;
}

.loader .dot:nth-of-type(22) {
    -webkit-animation-delay: -2.95s;
    animation-delay: -2.95s;
    left: -60px;
}

.loader .dot:nth-of-type(22)::before {
    -webkit-animation-delay: -2.95s;
    animation-delay: -2.95s;
}

.loader .dot:nth-of-type(23) {
    -webkit-animation-delay: -2.3s;
    animation-delay: -2.3s;
    left: -75px;
}

.loader .dot:nth-of-type(23)::before {
    -webkit-animation-delay: -2.3s;
    animation-delay: -2.3s;
}

.loader .dot:nth-of-type(24) {
    -webkit-animation-delay: -3.15s;
    animation-delay: -3.15s;
    left: -75px;
}

.loader .dot:nth-of-type(24)::before {
    -webkit-animation-delay: -3.15s;
    animation-delay: -3.15s;
}

.loader .dot:nth-of-type(25) {
    -webkit-animation-delay: -2.5s;
    animation-delay: -2.5s;
    left: -90px;
}

.loader .dot:nth-of-type(25)::before {
    -webkit-animation-delay: -2.5s;
    animation-delay: -2.5s;
}

.loader .dot:nth-of-type(26) {
    -webkit-animation-delay: -3.35s;
    animation-delay: -3.35s;
    left: -90px;
}

.loader .dot:nth-of-type(26)::before {
    -webkit-animation-delay: -3.35s;
    animation-delay: -3.35s;
}

.loader .dot::before {
    -webkit-animation-name: size-opacity;
    animation-name: size-opacity;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    background: blue;
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
}

.loader .dot:nth-of-type(even)::before {
    background-color: #ff47aa;
    box-shadow: inset 0 0 4px #ff1492;
}

@Keyframes movement {
    0% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
        z-index: 0;
    }
    50% {
        -webkit-transform: translate3d(0, 15px, 0);
        transform: translate3d(0, 15px, 0);
        z-index: 10;
    }
    100% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
        z-index: -5;
    }
}

@Keyframes size-opacity {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: .35;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


/* Second Loader */
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}


/* Third loader */

.container {
    height: 100vh;
    width: 100vw;
    font-family: Helvetica;
}

.loader {
    height: 20px;
    width: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;
}

.loader--dot:first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
}

.loader--dot:nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
}

.loader--dot:nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
}

.loader--dot:nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
}

.loader--dot:nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
}

.loader--dot:nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
}

.loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
}

.loader--text:after {
    content: "Loading";
    font-weight: bold;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes loader {
    15% {
        transform: translateX(0);
    }
    45% {
        transform: translateX(230px);
    }
    65% {
        transform: translateX(230px);
    }
    95% {
        transform: translateX(0);
    }
}

@keyframes loading-text {
    0% {
        content: "Loading";
    }
    25% {
        content: "Loading.";
    }
    50% {
        content: "Loading..";
    }
    75% {
        content: "Loading...";
    }
}
