.project-save-btn-container {
    padding: 4px 10px 10px 10px !important;
    height: 42px !important;
}

.form-panel-assign-name-container {
    padding: 10px !important;
}

.form-panel-add-follower-text {
    color: #02ABEE !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: normal !important;
    line-height: 1.57 !important;
    letter-spacing: 0.00714em !important;
}

.form-panel-save-btn {
    cursor: pointer !important;
    font-size: 20px !important;
}

.form-panel-save-btn.MuiIconButton-root {
    padding: 0 !important;
    position: absolute  !important;
    right: 20px !important;
    top: 10px !important;
}

.form-panel-user-icon {
    font-size: 30px !important;
    color: #77797c !important;
}

.form-panel-assign-container {
    padding-left: 12px !important;
}

.add-description-text {
    margin-top: 5px !important;
}

.for-height {
    height: 37px !important;
}

.add-tag-text {
    background-color: #c5e2ff9e !important;
    padding: 10px;
}

.tag-icon-container {
    font-size: 20px !important;
}

.add-tag-container-projects {
    margin-left: 5px !important;
    margin-top: 4px !important;
    font-size: 15px !important;
}

.task-items-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem !important;
    padding-bottom: 10px !important;
}

.divider-style {
    color: #77797c !important;
}

.form-panel-dependent-task-text {
    font-weight: 500 !important;
}

.form-panel-task-delete-icon {
    cursor: pointer;
}

.description-input-container {
    margin-top: 10px !important;
}

.assign-input-box {
    margin-top: -8px !important;
}

.form-panel-assign-approver-container {
    padding-left: 2px !important;
}

.dropdown-margins {
    margin-top: -11px !important;
}

.for-paddong-right {
    padding-right: 10px !important;
}

.details-header {
    padding: 5px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.detail-header-content-for-users-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    align-items: center;
    font-size: 11px;
}

.detail-header-content-for-functional-role {
    justify-content: space-between;
    width: 100%;
    margin-left: -8px !important;
    align-items: center;
    font-size: 11px;
}

#demo-simple-select-required-label {
    font-size: 10px !important;
}

.inline-flex {
    display: inline-flex;
}

.task-assignee-image {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 0.4rem;
}

.box-padding {
    padding: 2px 5px;
}

.tag-container-icon {
    position: absolute !important;
    z-index: 1 !important;
    padding: 6px 0 0 12px !important;
    font-size: 20px !important;
    color: #77797c !important;
}

.for-width-120 {
    width: 120px !important;
}

.for-width-200 {
    width: 200px !important;
}

.padding-right-3 {
    padding-right: 3px !important;
}

.effort-form-control {
    width: 150px !important;
    margin-top: -16px !important;
}

.margin-top-minus-16 {
    margin-top: -8px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}
.padding-for-description-container {
    padding: 15px 0 15px 10px !important;
}