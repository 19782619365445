.edit-project-card {
    padding: 16px !important;
    box-shadow: 0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%) !important;
}

.edit-project-header-actions {
    /* border-bottom: 1px dashed #02abee !important; */
    padding: 10px !important;
}

.edit-project-icons {
    font-size: 20px !important;
}

.back-icon {
    /* color: #02abee !important; */
    cursor: pointer;
}

.save-icon {
    /* color: #02abee !important; */
    cursor: pointer;
}

.cancel-icon {
    /* color: rgb(196, 39, 39) !important; */
    padding-right: 16px !important;
    padding-top: 2px !important;
    cursor: pointer;
}

.edit-project-inputs {
    padding: 10px !important;
}

.edit-project-footer-actions {
    margin-top: 20px !important;
    /* border-top: 1px dashed #02abee !important; */
    padding: 10px !important;
}

.edit-project-errorText {
    color: red !important;
}
