.form-panel-padding {
    height: 41px !important;
    padding: 7px 14px 11px 10px !important;
    /* border-bottom: 1px dashed #02ABEE !important; */
}

.form-panel-assign-name-container {
    padding: 10px 10px 0 10px !important;
}

.form-panel-followers-text {
    /* font-size: 12px !important; */
    /* color: #77797c !important; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
    /* font-weight: normal !important; */
    /* line-height: 1.57 !important; */
    /* letter-spacing: 0.00714em !important; */
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.form-panel-add-follower-text {
    color: #02ABEE !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: normal !important;
    line-height: 1.57 !important;
    letter-spacing: 0.00714em !important;
}

.form-panel-save-btn {
    /* color: #02ABEE !important; */
    cursor: pointer !important;
}

.form-panel-canel-btn-task {
    /* color:  red !important; */
    margin-top: 2px !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

.form-panel-user-icon {
    font-size: 30px !important;
    color: #77797c !important;
}

.form-panel-mini-text {
    /* font-size: 10px !important; */
    /* color: #77797c !important; */
    margin-top: 4px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
    /* font-weight: normal !important; */
    /* line-height: 1.57 !important; */
    /* letter-spacing: 0.00714em !important; */
}

.form-panel-assign-style {
    /* color: #02ABEE !important; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: normal !important;
    line-height: 1.57 !important;
    letter-spacing: 0.00714em !important; */
}

.form-panel-assign-container {
    /* margin-left: 5px !important; */
    padding-left: 12px !important;
}

.add-title-text {
    /* font-size: 25px !important; */
    /* color: #02ABEE !important; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
    /* font-weight: 500 !important; */
    /* line-height: 1.57 !important; */
    /* letter-spacing: 0.00714em !important; */
}

.add-description-text {
    /* font-size: 16px !important; */
    margin-top: 5px !important;
    /* color: #02ABEE !important; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
    /* font-weight: normal !important; */
    /* line-height: 1.57 !important; */
    /* letter-spacing: 0.00714em !important; */
}

.for-background-color {
    /* background-color: #f2f6fa !important; */
}

.for-height {
    height: 37px !important;
}

.add-tag-text {
    background-color: #c5e2ff9e !important;
    padding: 10px;
}

.tag-icon-container {
    font-size: 20px !important;
    /* color: #77797c !important; */
}

.add-tag-container {
    margin-left: 5px !important;
    font-size: 16px !important;
    margin-top: 4px !important;
    /* color: #02ABEE !important; */
}

.task-items-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;
}

.form-panel-bold-text {
    /* font-size: 15px !important; */
    /* color: #02ABEE !important; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
    /* font-weight: normal !important; */
    /* line-height: 1.57 !important; */
    /* letter-spacing: 0.00714em !important; */
    white-space: break-spaces;
    /* overflow: hidden;
    text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.divider-style {
    /* padding: 10px !important; */
    color: #77797c !important;
}

.form-panel-dependent-task-text {
    font-weight: 500 !important;
    /* font-size: 15px !important; */
    /* color: #77797c !important; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.57 !important;
    letter-spacing: 0.00714em !important; */
}

.form-panel-delete-icon {
    color: #77797c !important;
}

.description-input-container {
    margin-top: 10px !important;
}

.assign-input-box {
    margin-top: -8px !important;
}

.form-panel-assign-approver-container {
    padding-left: 2px !important;
}

.dropdown-margins {
    margin-top: -11px !important;
}

.for-paddong-right {
    padding-right: 10px !important;
}

.tasklist-names-container {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.for-ovarlapping-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.on-edit-task-view-predecessors {
    /* font-size: 14px !important; */
    /* color: #77797c !important; */
    /* margin-top: 2px !important; */
    margin-left: 5px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
    /* font-weight: normal !important; */
    /* line-height: 1.57 !important; */
    /* letter-spacing: 0.00714em !important; */
}

.submit-btn.MuiButton-root {
    min-width: 0% !important;
}

.tag-icon-span-in-formpanel {
    left: 0px;
    margin: -0.5rem;
    display: block;
    z-index: 1;
    position: absolute;
    margin-top: 1.8%;
    padding-left: 30px;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.margin-top-minus-2 {
    margin-top: -2px !important;
}

.margin-top-10 {
    margin-top: 5px !important;
}
