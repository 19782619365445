.draggable-dialog-title-container {
    cursor: move;
}

.draggable-dialog-title {
    font-size: 24px !important;
    font-weight: 500 !important;
}

.close-dialog-button {
    float: right;
    padding: 0px !important;
    height: 20px !important;
}
