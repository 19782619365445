.MuiChip-root .MuiChip-deleteIcon {
    color: #fff !important;
}

.MuiChip-clickable:hover, .MuiChip-clickable:focus {
    background-color: #02ABEE !important;
    color: #fff !important;
}

.user-name {
    margin-top: -5px !important;
    /* color: #02ABEE; */
    /* color: #02abeeb3; */
}

.user-name.MuiTypography-body1 {
    font-weight: 400 !important;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2.5rem;
}

.grid-container-two {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 83.5px;
}

.details-dependent-tasks {
    border-bottom: 1px dashed #77797c;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.concept-dependent.MuiTypography-body1 {
    font-weight: normal !important;
}

.final-iteration-of-presentation.MuiTypography-body1 {
    font-weight: normal !important;
    margin-bottom: 20px !important;
}

.blocking {
    color: #f5951ebd !important;
}

.blocked {
    /* color: #da2525d4 !important; */
}
