.team-list-count-assignee {
    width: 32px !important;
    height: 32px !important;
    padding: 2px;
    background: #CCF1FF !important;
    color: black !important;
    margin-left: -6px !important;
    border-radius: 50% !important;
    margin-bottom: 4px !important;
}

.team-list-count-assignee .MuiAvatar-colorDefault {
    background: none !important;
    color: normal !important;
}

.client-vendor-profile-image {
    display: inline-flex;
}

.user-image {
    width: 32px !important;
    height: 32px !important;
    padding: 2px !important;
    margin-left: -6px !important;
    border-radius: 50% !important;
    margin-bottom: 4px !important;
}

.checkedIconDivStyle-user {
    background-color: #ffff;
    border-radius: 50% !important;
    height: 19px !important;
    width: 19px !important;
    /* margin-top: 4px !important; */
    margin-left: 3px;
}

.notCheckedIconDivStyle-user {
    background-color: #ffff;
    border-radius: 50% !important;
    height: 19px !important;
    width: 19px !important;
    /* margin-top: 4px !important;
    margin-left: 5px !important; */
}

.custome-switch-color {
    color: #ffff !important;
}
